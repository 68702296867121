import React from 'react'
import HeaderComponent from '../components/HeaderComponent';
import WineListComponent from '../components/WineListComponent';
import WineListTitle from '../components/WineListTitle'
import { wineListData } from '../data';
import transition from '../transition';

const WineList = () => {
  const { signatureCocktails, aperitifs, champagneList, sparklingWines, whiteWines } = wineListData;
  return (
    <> 
        <HeaderComponent translate="translateX(100%)" heading="Our wine list"/>
        <section className='flex flex-col pt-5 pb-10'>
        <WineListTitle title="Signature cocktails"/> 
            {signatureCocktails.map((item, index) => {
                const {name, ingredients, type, price, serving_size} = item;
                return <div className='container mx-auto px-8 lg:px-20' key={index}><WineListComponent name={name} ingredients={ingredients} type={type} price={price} serving_size={serving_size}/></div>
            })}
          <WineListTitle title="Aperitifs"/> 
            {aperitifs.map((item, index) => {
                const {name, ingredients, type, price, serving_size} = item;
                return <div className='container mx-auto px-8 lg:px-20' key={index}><WineListComponent name={name} ingredients={ingredients} type={type} price={price} serving_size={serving_size}/></div>
            })}
          <WineListTitle title="Champagne List"/>
          {champagneList.map((item, index) => {
              const {name, ingredients, type, price, serving_size} = item;
              return <div className='container mx-auto px-8 lg:px-20' key={index}><WineListComponent name={name} ingredients={ingredients} type={type} price={price} serving_size={serving_size} /></div>
          })}
          <WineListTitle title="Sparkling Wines"/>
          {sparklingWines.map((item, index) => {
              const {name, ingredients, type, price, serving_size, serving_size2, price2} = item;
              return <div className='container mx-auto px-8 lg:px-20' key={index}><WineListComponent name={name} ingredients={ingredients} type={type} price={price} serving_size={serving_size} serving_size2={serving_size2} price2={price2}/></div>
          })}
          <WineListTitle title="White Wines"/>
          {whiteWines.map((item, index) => {
              const {name, ingredients, type, price, serving_size, serving_size2, price2, price3, serving_size3} = item;
              return <div className='container mx-auto px-8 lg:px-20' key={index}><WineListComponent name={name} ingredients={ingredients} type={type} price={price} serving_size={serving_size} serving_size2={serving_size2} price2={price2} price3={price3} serving_size3={serving_size3}/></div>
          })}
        </section>
    </>
  )
}

export default transition(WineList);
